import React, { useEffect, useRef, Suspense } from 'react';
import { connect } from 'react-redux';
import { useIntl } from 'react-intl';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import * as FullStory from '@fullstory/browser';
import { usePageViews, useScript } from '@whitelabel/helpers/hooks';
import { IUser, ICustomer } from '@whitelabel/helpers/types';
import { ZendeskChatProvider } from '@whitelabel/xcover-shared/components/PartnerZendeskChat';
import * as actions from '../../actions';
import { useCookieYesEventListener } from '../../helpers/hooks/cookieYes';
import { routes } from '../../routes/routes';
import StyledLoading from '../../styled/StyledLoading';
import ErrorBoundary from '../ErrorBoundary';
import ErrorPage from '../../components/ErrorPage';
import { storeINSInLocalStorage } from '../../helpers/bookings';
import { initializeFullStory } from '../../helpers/utils';

interface IAppProps {
  authenticating: boolean;
  checkSession: (...args: any[]) => any;
  customer?: ICustomer;
  user?: IUser;
}

function App({ checkSession, authenticating }: IAppProps) {
  const { pathname } = useLocation();
  const pathRef = useRef(pathname);
  const cookieYesScript = window?.location.hostname.startsWith('localhost')
    ? ''
    : 'https://cdn-cookieyes.com/client_data/ad2300bcca186c579bea3c5a/script.js';

  const { locale } = useIntl();

  useEffect(() => {
    const pathLanguage = pathname.split('/')[1]?.toLowerCase();
    if (pathLanguage !== locale) {
      window.location.reload();
    }
  }, [pathname]);

  useScript(cookieYesScript, 'cookieyes');

  useCookieYesEventListener();
  usePageViews();

  useEffect(() => {
    checkSession();
  }, [checkSession]);

  useEffect(() => {
    storeINSInLocalStorage();
    if (pathRef.current !== pathname) {
      window.scroll({ top: 0, left: 0 });
      pathRef.current = pathname;
    }
    if (
      !window.isHeadless &&
      !FullStory.isInitialized() &&
      !pathname.includes('/pds/') &&
      !pathname.includes('/partners/uber-driver')
    ) {
      initializeFullStory();
    }
  }, [pathname]);

  return authenticating ? (
    <StyledLoading />
  ) : (
    <ZendeskChatProvider>
      <Suspense fallback={<StyledLoading />}>
        <Switch>
          <Redirect
            from="/(.+)/"
            strict
            exact
            to={{ pathname: pathname.slice(0, -1), search: window.location.search, hash: window.location.hash }}
          />

          {routes.map(({ component: Component, ...restProps }) => (
            <Route
              {...restProps}
              render={(routeProps) => (
                <ErrorBoundary>
                  <Component {...routeProps} />
                </ErrorBoundary>
              )}
            />
          ))}
          <Route path="/" exact render={() => <StyledLoading />} />
          <Route render={(routerProps) => <ErrorPage {...routerProps} isNoMatch />} />
        </Switch>
      </Suspense>
    </ZendeskChatProvider>
  );
}

const mapStateToProps = (state: any) => ({
  authenticating: state.user.checkingSession,
  user: state.user.data,
  customer: state.customer.data,
});

const mapDispatchToProps = {
  checkSession: actions.checkSession,
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
